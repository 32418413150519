import React, { useEffect, useState } from 'react';

import {
  Typography,
  Card,
  CardContent,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from '@mui/material';
import { Timestamp, serverTimestamp } from 'firebase/firestore';

import { useAuth } from '@/context/AuthContext';
import {
  useInterestFormSubmissions,
  InterestFormSubmission,
} from '@/context/InterestFormSubmissionContext';
import {
  MeetAndGreetSubmission,
  useMeetAndGreetFormSubmissions,
} from '@/context/MeetAndGreetSubmissionContext';

import UserFormSubmissionCard from './UserSubmissionCard';

const FormSubmissions: React.FC = () => {
  const { getInterestFormSubmissions, updateInterestFormSubmission } =
    useInterestFormSubmissions();
  const { getMeetAndGreetSubmissions, updateMeetAndGreetFormSubmission } =
    useMeetAndGreetFormSubmissions();

  const [interestFormSubmissions, setInterestFormSubmissions] = useState<
    InterestFormSubmission[]
  >([]);
  const [meetAndGreetSubmissions, setMeetAndGreetSubmissions] = useState<
    MeetAndGreetSubmission[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [sortOption, setSortOption] = useState<string>('date-submitted');

  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchInterestSubmissions = async () => {
      try {
        const submissionsList = await getInterestFormSubmissions();
        setInterestFormSubmissions(
          submissionsList.map((sub) => ({ ...sub, formType: 'interest' })),
        );
      } catch (error) {
        console.error('Error fetching submissions:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchMeetAndGreetSubmissions = async () => {
      try {
        const meetAndGreetSubmissions = await getMeetAndGreetSubmissions();
        setMeetAndGreetSubmissions(
          meetAndGreetSubmissions.map((sub) => ({
            ...sub,
            formType: 'meetAndGreet',
          })),
        );
      } catch (error) {
        console.error('Error fetching submissions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInterestSubmissions();
    fetchMeetAndGreetSubmissions();
  }, [getInterestFormSubmissions, getMeetAndGreetSubmissions]);

  if (loading) {
    return <Typography>Loading form submissions...</Typography>;
  }

  const handleSortChange = (event: SelectChangeEvent<string>) => {
    setSortOption(event.target.value as string);
  };

  const sortSubmissions = (
    submissions: InterestFormSubmission[] | MeetAndGreetSubmission[],
  ) => {
    return submissions.sort((a, b) => {
      if (sortOption === 'date-submitted') {
        return b.createdAt.seconds - a.createdAt.seconds;
      } else if (sortOption === 'date-updated') {
        if (!a.updatedAt || !b.updatedAt) return 0;

        return b.updatedAt.seconds - a.updatedAt.seconds;
      } else if (sortOption === 'alphabetical') {
        return a.email.localeCompare(b.email);
      } else if (sortOption === 'checked') {
        return Number(b.checked) - Number(a.checked);
      }
      return 0;
    });
  };

  const uncheckedSubmissions = [
    ...interestFormSubmissions.filter((sub) => !sub.checked),
    ...meetAndGreetSubmissions.filter((sub) => !sub.checked),
  ];

  const checkedSubmissions = [
    ...interestFormSubmissions.filter((sub) => sub.checked),
    ...meetAndGreetSubmissions.filter((sub) => sub.checked),
  ];

  const orderedSubmissions = [...uncheckedSubmissions, ...checkedSubmissions];
  const sortedSubmissions = sortSubmissions([...orderedSubmissions] as
    | InterestFormSubmission[]
    | MeetAndGreetSubmission[]);

  const renderInterestFormSubmission = (submission: InterestFormSubmission) => {
    return (
      <UserFormSubmissionCard
        key={submission.id}
        submission={{
          adminComments: submission.adminComments,
          checked: submission.checked,
          comments: submission.comments && `Comments: ${submission.comments}`,
          createdAt: submission.createdAt,
          email: submission.email,
          formType: submission.formType,
          id: submission.id,
          options: submission.interests,
          updatedBy: submission.updatedBy,
        }}
        onAdminUpdate={onAdminUpdate}
      />
    );
  };

  const renderComments = (submission: MeetAndGreetSubmission) => {
    let comments = '';
    if (submission.extraInfo) {
      comments += `Extra Info: ${submission.extraInfo}`;
    }

    if (submission.about) {
      comments += `About: ${submission.about}`;
    }

    return comments;
  };

  const renderMeetAndGreetFormSubmission = (
    submission: MeetAndGreetSubmission,
  ) => {
    return (
      <UserFormSubmissionCard
        key={submission.id}
        submission={{
          adminComments: submission.adminComments,
          checked: submission.checked,
          comments:
            (submission.extraInfo || submission.about) &&
            renderComments(submission),
          createdAt: submission.createdAt,
          email: submission.email,
          formType: submission.formType,
          id: submission.id,
          options: submission.helpOptions,
          updatedBy: submission.updatedBy,
        }}
        onAdminUpdate={onAdminUpdate}
      />
    );
  };

  const onAdminUpdate = async (
    id: string,
    formType: string,
    updateObj:
      | Partial<InterestFormSubmission>
      | Partial<MeetAndGreetSubmission>,
  ) => {
    const hydratedObj = {
      ...updateObj,
      updatedAt: serverTimestamp() as Timestamp,
      updatedBy: currentUser?.email || '',
    };

    try {
      if (formType === 'interest') {
        await updateInterestFormSubmission(id, hydratedObj);
        setInterestFormSubmissions((prevSubmissions) =>
          prevSubmissions.map((submission) =>
            submission.id === id
              ? { ...submission, ...hydratedObj }
              : submission,
          ),
        );
      } else {
        await updateMeetAndGreetFormSubmission(id, hydratedObj);
        setMeetAndGreetSubmissions((prevSubmissions) =>
          prevSubmissions.map((submission) =>
            submission.id === id
              ? { ...submission, ...hydratedObj }
              : submission,
          ),
        );
      }
    } catch (error) {
      console.error('Error updating submission checked status:', error);
    }
  };

  return (
    <Card
      variant="outlined"
      sx={{ marginBottom: 2 }}
      data-testid="admin-form-submissions"
    >
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h5" gutterBottom>
            Form Submissions
          </Typography>
          <FormControl sx={{ marginBottom: 2 }}>
            <InputLabel id="sort-select-label">Sort By</InputLabel>
            <Select
              labelId="sort-select-label"
              value={sortOption}
              label="Sort By"
              onChange={handleSortChange}
              size="small"
            >
              <MenuItem value="date-submitted">Date Submitted</MenuItem>
              <MenuItem value="date-updated">Date Updated</MenuItem>
              <MenuItem value="alphabetical">Alphabetically</MenuItem>
              <MenuItem value="checked">Contacted</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <Box>
          {sortedSubmissions.map((submission) =>
            submission.formType === 'interest'
              ? renderInterestFormSubmission(
                  submission as InterestFormSubmission,
                )
              : renderMeetAndGreetFormSubmission(
                  submission as MeetAndGreetSubmission,
                ),
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default FormSubmissions;
