import React, { useEffect, useState } from 'react';

import { Box, Grid, Typography, Dialog } from '@mui/material';
import { logEvent } from 'firebase/analytics';
import { getFunctions, httpsCallable } from 'firebase/functions';
import toast from 'react-hot-toast';

import InputWithButton from '@/atoms/InputWithButton';
import InterestForm from '@/atoms/InterestForm';
import { STRINGS } from '@/constants/strings';
import { validateEmail } from '@/utils/validations';
import { parseMailchimpError } from '@/utils/waitlist';

import { analytics } from '../firebaseConfig';

const NewsletterSignup = () => {
  const functions = getFunctions();

  const [focusEmailInput, setFocusEmailInput] = useState<boolean>(false);
  const [isInterestFormDialogOpen, setIsInterestFormDialogOpen] =
    useState<boolean>(false);
  const [submittedEmail, setSubmittedEmail] = useState<string>('');

  useEffect(() => {
    if (focusEmailInput) {
      setFocusEmailInput(false);
    }
  }, [focusEmailInput]);

  const closeInterestForm = () => {
    setIsInterestFormDialogOpen(false);
    setSubmittedEmail('');
  };

  const handleNewsletterSignup = async (email: string) => {
    if (!validateEmail(email)) {
      toast.error(STRINGS.invalidEmailError);
      return;
    }

    try {
      const addEmailToMailchimp = httpsCallable(
        functions,
        'addEmailToMailchimp',
      );
      await addEmailToMailchimp({ email: email.trim() });
      toast.success(STRINGS.mailchimpSubmitToastSuccess);
      setIsInterestFormDialogOpen(true);
      setSubmittedEmail(email);
      logEvent(analytics, 'mailchimp_signup');
      return true; // Add return true for successful submission
    } catch (error) {
      const errorMessage = parseMailchimpError(error);
      toast.error(errorMessage);

      return false;
    }
  };

  return (
    <>
      <Box
        textAlign="center"
        sx={{
          overflow: 'hidden',
          position: 'relative',
          width: '100%',
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          display="flex"
          flexDirection="column"
          data-testid="newsletter-signup"
        >
          <Box sx={{ fontFamily: 'Bungee' }}>{STRINGS.callToActionTitle}</Box>
          <Typography
            variant="body2"
            gutterBottom
            sx={{ marginBottom: '10px' }}
          >
            Not ready for the waitlist? Subscribe to our newsletter - pinky
            promise not to spam you!
          </Typography>

          <InputWithButton
            focusEmailInput={focusEmailInput}
            handleSubmit={async (email) => {
              handleNewsletterSignup(email);
            }}
            placeholder={STRINGS.newsletterSignupInputPlaceholder}
            buttonText={STRINGS.newsletterSignupButtonText}
          />
        </Grid>
      </Box>

      <Dialog open={isInterestFormDialogOpen} onClose={closeInterestForm}>
        <InterestForm
          closeInterestForm={closeInterestForm}
          email={submittedEmail}
        />
      </Dialog>
    </>
  );
};

export default NewsletterSignup;
