import React, { useRef } from 'react';

const VideoPlayer: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: '#ffc43d',
        display: 'flex',
        justifyContent: 'center',
        maxHeight: '600px',
        overflow: 'hidden',
      }}
    >
      <video
        ref={videoRef}
        autoPlay
        muted
        playsInline
        style={{
          height: 'auto',
          maxHeight: '600px',
          objectFit: 'contain',
          width: '100%',
        }}
      >
        <source src="/snail.webm" type="video/webm" />
        Your browser does not support WebM videos.
      </video>
    </div>
  );
};

export default VideoPlayer;
