import React from 'react';

import { Box, Container, Grid } from '@mui/material';

import NewsletterSignup from './NewsletterSignup';
import Socials from './Socials';

const Footer: React.FC = () => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: 'primary.main',
        mt: 'auto',
        py: 6,
      }}
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={4}
          justifyContent="center"
          alignItems="flex-start"
        >
          {/* Newsletter Signup */}
          <Grid item xs={12} md={6}>
            <NewsletterSignup />
          </Grid>

          {/* Socials */}
          <Grid item xs={12} md={6}>
            <Socials />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
