import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';

import { Hardware } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import toast from 'react-hot-toast';

import { STRINGS } from '@/constants/strings';
import { cleanEmail, validateEmail } from '@/utils/validations';

interface InputWithButtonProps {
  buttonText: string;
  focusEmailInput: boolean;
  handleSubmit: (email: string) => Promise<void>;
  placeholder: string;
}

const InputWithButton: React.FC<InputWithButtonProps> = ({
  focusEmailInput,
  handleSubmit,
  placeholder,
  buttonText,
}) => {
  const [email, setEmail] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const emailInputRef = React.useRef<HTMLInputElement>(null);

  const theme = useTheme();

  useEffect(() => {
    if (focusEmailInput && emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, [focusEmailInput]);

  const handleEmailSubmit = async () => {
    const cleanedEmail = cleanEmail(email);

    if (!validateEmail(cleanedEmail)) {
      toast.error(STRINGS.invalidEmailError);
      return;
    }

    setIsSubmitting(true);
    try {
      await handleSubmit(cleanedEmail);
      setEmail(''); // Reset on success
    } catch (error) {
      // no-op
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleEmailSubmit();
    }
  };

  const inputMinWidth =
    window.innerWidth < theme.breakpoints.values.sm ? '150px' : '200px';

  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div style={{ display: 'flex', width: '100%' }}>
        <input
          style={{
            border: 'none',
            borderBottomLeftRadius: '20px',
            borderTopLeftRadius: '20px',
            flex: 1,
            marginRight: '-1px',
            minWidth: inputMinWidth,
            outline: 'none',
            padding: '10px 20px',
          }}
          type="text"
          placeholder={placeholder}
          value={email}
          onChange={handleChange}
          onKeyDown={handleKeyPress}
          ref={emailInputRef}
        />
        <button
          style={{
            alignItems: 'center',
            backgroundColor: '#92D5E6',
            border: 'none',
            borderBottomRightRadius: '20px',
            borderTopRightRadius: '20px',
            color: theme.palette.primary.contrastText,
            cursor: isSubmitting ? 'default' : 'pointer',
            display: 'flex',
            fontFamily: "'Bungee', sans-serif",
            fontSize: '0.875rem',
            fontWeight: 500,
            height: '38px',
            justifyContent: 'center',
            padding: isSubmitting ? '15px' : '10px 20px',
            textTransform: 'none',
          }}
          onClick={handleEmailSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <Hardware
              sx={{
                animation: 'hammer-move 0.55s ease-in-out infinite',
                fontSize: '25px',
                marginBottom: '3px',
                marginRight: '13px',
                transformOrigin: 'bottom center',
              }}
            />
          ) : (
            buttonText
          )}
        </button>
      </div>
    </div>
  );
};

export default InputWithButton;
