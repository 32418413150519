const ROUTES = {
  ABOUT: '/about',
  ADMIN: '/admin',
  EVENTS: '/events',
  GETINVOLVED: '/getinvolved',
  HOME: '/',
  WAITLIST: '/waitlist',
};

export default ROUTES;
