import React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Footer from './atoms/Footer';
import Navbar from './atoms/Navbar';
import ROUTES from './constants/routes';
import { AppProvider } from './context/AppContext';
import { AuthProvider } from './context/AuthContext';
import { InterestFormSubmissionsProvider } from './context/InterestFormSubmissionContext';
import { MeetAndGreetFormSubmissionsProvider } from './context/MeetAndGreetSubmissionContext';
import { TasksProvider } from './context/TasksContext';
import { WaitlistSubmissionsProvider } from './context/WaitlistSubmissionContext';
import About from './pages/About';
import Admin from './pages/Admin';
import Events from './pages/Events';
import GetInvolved from './pages/GetInvolved';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import Waitlist from './pages/Waitlist';
import GlobalStyles from './styles/GlobalStyles';
import theme from './styles/theme';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />

      <AuthProvider>
        <AppProvider>
          <TasksProvider>
            <InterestFormSubmissionsProvider>
              <MeetAndGreetFormSubmissionsProvider>
                <WaitlistSubmissionsProvider>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      minHeight: '100vh',
                    }}
                  >
                    <Router>
                      <CssBaseline />
                      <Navbar />

                      <Routes>
                        <Route path={ROUTES.HOME} element={<Home />} />
                        <Route path={ROUTES.ABOUT} element={<About />} />
                        <Route path={ROUTES.EVENTS} element={<Events />} />
                        <Route
                          path={ROUTES.GETINVOLVED}
                          element={<GetInvolved />}
                        />
                        <Route path={ROUTES.ADMIN} element={<Admin />} />
                        <Route path={ROUTES.WAITLIST} element={<Waitlist />} />
                        <Route path="*" element={<NotFound />} />
                      </Routes>

                      <Footer />

                      <Toaster
                        position="bottom-left"
                        toastOptions={{
                          duration: 6000,
                        }}
                      />
                    </Router>
                  </div>
                </WaitlistSubmissionsProvider>
              </MeetAndGreetFormSubmissionsProvider>
            </InterestFormSubmissionsProvider>
          </TasksProvider>
        </AppProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
