import React, { useEffect, useState } from 'react';

import { Container } from '@mui/material';

import ImageWithOverlay from '@/atoms/ImageWithOverlay';
import WaitlistForm from '@/atoms/WaitlistForm';
import AboutUs from '@/components/About/AboutUs';
import HowItWorks from '@/components/About/HowItWorks';

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const [focusEmailInput, setFocusEmailInput] = useState<boolean>(false);

  useEffect(() => {
    if (focusEmailInput) {
      setFocusEmailInput(false);
    }
  }, [focusEmailInput]);

  const handleEmailClick = () => {
    setFocusEmailInput(true);
  };

  return (
    <>
      <ImageWithOverlay />
      <WaitlistForm />
      <Container sx={{ mb: 5, mt: 5 }} maxWidth="md">
        <AboutUs handleEmailClick={handleEmailClick} />
        <HowItWorks />
      </Container>
    </>
  );
};

export default Home;
