export const validateEmail = (email: string): boolean => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // Additional checks for edge cases
  if (email.includes('..') || email.includes(' ')) return false;
  return regex.test(email);
};

export const cleanEmail = (email: string): string => {
  return email.trim().toLowerCase();
};
