import React, { useEffect, useState } from 'react';

import { Typography, Card, CardContent, Box, Grid } from '@mui/material';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend } from 'recharts';

import { useInterestFormSubmissions } from '@/context/InterestFormSubmissionContext';
import { useMeetAndGreetFormSubmissions } from '@/context/MeetAndGreetSubmissionContext';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF4560'];

interface Data {
  title: string;
  type: string;
  value: number;
}

interface CustomPieChartProps {
  data: Data[];
  title: string;
  totalEntries: number;
}

const CustomPieCharts = () => {
  const [interestFormSubmissionData, setInterestFormSubmissionData] = useState<
    Data[]
  >([]);
  const [interestFormTotalEntries, setInterestFormTotalEntries] = useState(0);
  const [meetAndGreetSubmissionData, setMeetAndGreetSubmissionData] = useState<
    Data[]
  >([]);
  const [meetAndGreetTotalEntries, setMeetAndGreetTotalEntries] = useState(0);
  const [loading, setLoading] = useState(true);

  const { getInterestFormSubmissions } = useInterestFormSubmissions();
  const { getMeetAndGreetSubmissions } = useMeetAndGreetFormSubmissions();

  useEffect(() => {
    const fetchInterestSubmissions = async () => {
      try {
        const submissionsList = await getInterestFormSubmissions();
        setInterestFormTotalEntries(submissionsList.length);
        const interestData = submissionsList.reduce((acc, sub) => {
          const interests = sub.interests;

          if (!interests) {
            return acc;
          }

          interests.forEach((interest) => {
            const existingInterest = acc.find(
              (i) => i.title === interest.title,
            );

            if (existingInterest) {
              existingInterest.value += 1;
            } else {
              acc.push({
                title: interest.title,
                type: interest.type,
                value: 1,
              });
            }
          });

          return acc;
        }, [] as Data[]);
        setInterestFormSubmissionData(interestData);
      } catch (error) {
        console.error('Error fetching submissions:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchMeetAndGreetSubmissions = async () => {
      try {
        const meetAndGreetSubmissions = await getMeetAndGreetSubmissions();
        setMeetAndGreetTotalEntries(meetAndGreetSubmissions.length);
        const helpData = meetAndGreetSubmissions.reduce((acc, sub) => {
          const helpOptions = sub.helpOptions;

          if (!helpOptions) {
            return acc;
          }

          helpOptions.forEach((help) => {
            const existingHelp = acc.find((i) => i.title === help.title);

            if (existingHelp) {
              existingHelp.value += 1;
            } else {
              acc.push({
                title: help.title,
                type: help.type,
                value: 1,
              });
            }
          });

          return acc;
        }, [] as Data[]);
        setMeetAndGreetSubmissionData(helpData);
      } catch (error) {
        console.error('Error fetching submissions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchInterestSubmissions();
    fetchMeetAndGreetSubmissions();
  }, [getInterestFormSubmissions, getMeetAndGreetSubmissions]);

  if (loading) {
    return <Typography>Loading pie charts...</Typography>;
  }

  return (
    <Box sx={{ marginBottom: 2 }} data-testid="admin-pie-charts">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <CustomPieChart
            title="Interests"
            data={interestFormSubmissionData}
            totalEntries={interestFormTotalEntries}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomPieChart
            title="Help"
            data={meetAndGreetSubmissionData}
            totalEntries={meetAndGreetTotalEntries}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const CustomPieChart: React.FC<CustomPieChartProps> = ({
  title,
  data,
  totalEntries,
}) => {
  const sortedData = [...data].sort((a, b) => b.value - a.value);
  const total = data.reduce((sum, entry) => sum + entry.value, 0);

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body2" gutterBottom>
          Total Entries: {totalEntries}
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={sortedData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {sortedData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Legend
              layout="vertical"
              align="right"
              verticalAlign="middle"
              width={210}
              formatter={(value, entry, index) => {
                const percent = (
                  (sortedData[index].value / total) *
                  100
                ).toFixed(2);
                return <span>{`${sortedData[index].title}: ${percent}%`}</span>;
              }}
            />
          </PieChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default CustomPieCharts;
