import React, { useEffect, useState } from 'react';

import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { STRINGS } from '@/constants/strings';

import VideoPlayer from './VideoPlayer';

const useStyles = makeStyles(() => ({
  '@keyframes blink-caret': {
    '100%': { opacity: 1 },
    '50%': { opacity: 0 },
  },
  container: {
    alignItems: 'center',
    background: '#FFC43D',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  image: {
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    width: '100%',
    zIndex: -1,
  },
  text: {
    '&::after': {
      animation: '$blink-caret 1s step-end infinite',
      color: '#EF4255',
      content: "'|'",
      opacity: 1,
    },
    color: 'black',
    fontFamily: 'Courier New, Courier, monospace',
    fontSize: '1.2rem',
    lineHeight: 1.1,
    paddingRight: '0.1em',
    position: 'relative',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    zIndex: 1,
  },
}));

const ImageWithOverlay = () => {
  const classes = useStyles();
  const fullText = STRINGS.homePageSubtitle;
  const [text, setText] = useState('');
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count < fullText.length) {
      const timer = setTimeout(() => {
        setText((prevText) => prevText + fullText.charAt(count));
        setCount((prevCount) => prevCount + 1);
      }, 100); // Control the typing speed here
      return () => clearTimeout(timer);
    }
  }, [count, fullText]);

  return (
    <Box className={classes.container}>
      <VideoPlayer />
      <Container
        sx={{
          alignItems: 'end',
          display: 'flex',
          height: '100%',
          justifyContent: 'start',
          minHeight: '72px',
          pb: 1,
          top: 0,
          zIndex: 1,
        }}
        maxWidth="md"
      >
        <div className={classes.text}>{text}</div>
      </Container>
    </Box>
  );
};

export default ImageWithOverlay;
