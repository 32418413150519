import React, { useState, useEffect } from 'react';

import SendIcon from '@mui/icons-material/Send';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from '@mui/material';
import toast from 'react-hot-toast';

import { STRINGS, initialHelpOptions } from '@/constants/strings';
import { useMeetAndGreetFormSubmissions } from '@/context/MeetAndGreetSubmissionContext';
import { validateEmail } from '@/utils/validations';

export interface HelpOption {
  title: string;
  type: string;
}

const validatePhoneNumber = (phoneNumber: string) => {
  const re = /^[0-9]{10}$/;
  return re.test(phoneNumber);
};

const GetInvolved: React.FC = () => {
  const { addMeetAndGreetFormSubmission } = useMeetAndGreetFormSubmissions();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [about, setAbout] = useState('');
  const [selectedHelpOptions, setSelectedHelpOptions] = useState<HelpOption[]>(
    [],
  );
  const [helpOptions, setHelpOptions] =
    useState<HelpOption[]>(initialHelpOptions);
  const [customHelpOptionTitle, setCustomHelpOptionTitle] =
    useState<string>('');
  const [customHelpError, setCustomHelpError] = useState('');
  const [extraInfo, setExtraInfo] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

  useEffect(() => {
    setIsEmailValid(validateEmail(email));
  }, [email]);

  useEffect(() => {
    setIsPhoneNumberValid(
      phoneNumber === '' || validatePhoneNumber(phoneNumber),
    );
  }, [phoneNumber]);

  const handleToggleHelpOption = (option: HelpOption) => {
    setSelectedHelpOptions((prev) =>
      prev.find((i) => i.title === option.title)
        ? prev.filter((i) => i.title !== option.title)
        : [...prev, option],
    );
  };

  const addCustomHelpOption = () => {
    const isFilled = Boolean(customHelpOptionTitle);
    const isDuplicate = helpOptions
      .map((option) => option.title.toLowerCase())
      .includes(customHelpOptionTitle.toLowerCase());

    if (!isFilled) {
      setCustomHelpError(STRINGS.requiredTitleError);
      return;
    }

    if (isDuplicate) {
      setCustomHelpError(STRINGS.duplicateHelpOptionError);
      return;
    }

    const customHelpOption = {
      title: customHelpOptionTitle,
      type: 'custom',
    };
    setSelectedHelpOptions((prev) => [...prev, customHelpOption]);
    setHelpOptions((prev) => [...prev, customHelpOption]);
    setCustomHelpOptionTitle('');
    setCustomHelpError('');
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      await addMeetAndGreetFormSubmission({
        about,
        email,
        extraInfo,
        helpOptions: selectedHelpOptions,
        name,
        phoneNumber,
      });

      toast.success('Form submitted successfully!');
      setName('');
      setEmail('');
      setPhoneNumber('');
      setAbout('');
      setHelpOptions(initialHelpOptions);
      setSelectedHelpOptions([]);
      setCustomHelpOptionTitle('');
      setExtraInfo('');
    } catch (error) {
      toast.error('Failed to submit the form. Please try again.');
    }
    setIsSubmitting(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: 2,
        margin: '0 auto',
        maxWidth: 600,
        mb: { sm: 4, xs: 0 },
        mt: { sm: 4, xs: 0 },
        p: 4,
      }}
    >
      <Typography variant="h4" gutterBottom>
        Hi there. We&apos;re Brooklyn Spark.
      </Typography>
      <Typography variant="body1" paragraph>
        We&apos;re thrilled that you are interested in helping build our
        community maker space! We&apos;d love to get to know you better,
        understand your preferences, and stay in touch. Your answers are not
        binding and we&apos;ll keep it all confidential.
      </Typography>
      <Box component="form" noValidate autoComplete="off">
        <TextField
          size="small"
          label="Name"
          name="name"
          id="name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          size="small"
          label="Email"
          type="email"
          name="email"
          id="email"
          fullWidth
          margin="dense"
          value={email}
          error={!isEmailValid && email !== ''}
          helperText={
            !isEmailValid && email !== '' ? 'Invalid email address' : ''
          }
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <TextField
          size="small"
          label="Phone Number"
          name="phoneNumber"
          id="phoneNumber"
          fullWidth
          margin="dense"
          value={phoneNumber}
          error={!isPhoneNumberValid && phoneNumber !== ''}
          helperText={
            !isPhoneNumberValid && phoneNumber !== ''
              ? 'Invalid phone number'
              : ''
          }
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <TextField
          size="small"
          label="Tell us a bit about yourself and what brought you to Brooklyn Spark :)"
          fullWidth
          margin="dense"
          name="about"
          id="about"
          multiline
          rows={4}
          value={about}
          onChange={(e) => setAbout(e.target.value)}
        />
        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          How would you like to help out? (Check all that apply)
        </Typography>
        <FormControl component="fieldset">
          <FormGroup>
            {helpOptions.map((option: HelpOption) => (
              <FormControlLabel
                key={option.title}
                control={
                  <Checkbox
                    checked={Boolean(
                      selectedHelpOptions.find((i) => i.title === option.title),
                    )}
                    onChange={() => handleToggleHelpOption(option)}
                    name={option.title}
                  />
                }
                label={option.title}
              />
            ))}
            <Box sx={{ alignItems: 'center', display: 'flex', mb: 2 }}>
              <TextField
                label="Other"
                size="small"
                margin="dense"
                value={customHelpOptionTitle}
                id="customHelpOptionTitle"
                onChange={(e) => setCustomHelpOptionTitle(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    addCustomHelpOption();
                  }
                }}
                sx={{ flex: 1, mr: 1 }}
              />
              <Button
                variant="outlined"
                onClick={addCustomHelpOption}
                disabled={!customHelpOptionTitle}
              >
                Add
              </Button>
            </Box>
            {customHelpError && (
              <Typography color="error" variant="body2">
                {customHelpError}
              </Typography>
            )}
          </FormGroup>
        </FormControl>
        <TextField
          label="Anything else you want to share"
          fullWidth
          size="small"
          margin="dense"
          multiline
          rows={4}
          value={extraInfo}
          onChange={(e) => setExtraInfo(e.target.value)}
        />
        <LoadingButton
          loading={isSubmitting}
          endIcon={<SendIcon />}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          loadingPosition="end"
          disabled={!isEmailValid}
          sx={{ mt: 2 }}
        >
          {STRINGS.formSubmitButtonText}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default GetInvolved;
