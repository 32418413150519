import React, { createContext, ReactNode, useContext } from 'react';

import {
  collection,
  DocumentData,
  getDocs,
  Timestamp,
} from 'firebase/firestore';

import { addNewAnonymousItem } from '@/utils/firebaseUtils';

import { firestore } from '../firebaseConfig';

export interface WaitlistSubmission {
  createdAt: Timestamp;
  email: string;
  id: string;
}

export interface WaitlistFormContent {
  email: string;
}

interface WaitlistSubmissionsContextType {
  addWaitlistSubmission: (formContent: WaitlistFormContent) => Promise<void>;
  getWaitlistSubmissions: () => Promise<WaitlistSubmission[]>;
}

const WaitlistSubmissionsContext =
  createContext<WaitlistSubmissionsContextType>({
    addWaitlistSubmission: async () => {},
    getWaitlistSubmissions: async () => [],
  });

export const useWaitlistSubmissions = () =>
  useContext(WaitlistSubmissionsContext);

interface WaitlistSubmissionsProviderProps {
  children: ReactNode;
}

export const WaitlistSubmissionsProvider = ({
  children,
}: WaitlistSubmissionsProviderProps) => {
  const addWaitlistSubmission = async (formContent: WaitlistFormContent) => {
    if (!formContent.email) {
      throw new Error('Email is required');
    }

    try {
      await addNewAnonymousItem('waitlistSubmissions', formContent);
    } catch (error) {
      console.error('Error adding waitlist submission:', error);
      throw error;
    }
  };

  const getWaitlistSubmissions = async (): Promise<WaitlistSubmission[]> => {
    try {
      const submissionsCol = collection(firestore, 'waitlistSubmissions');
      const submissionSnapshot = await getDocs(submissionsCol);

      // Removing duplicate submissions by keeping the earliest submission
      const earliestSubmissionsMap = new Map<string, DocumentData>();

      submissionSnapshot.docs.forEach((doc) => {
        const data = doc.data();
        const email = data.email;

        if (!earliestSubmissionsMap.has(email)) {
          // Add the first submission for the email
          earliestSubmissionsMap.set(email, { id: doc.id, ...data });
        } else {
          // Compare createdAt dates to keep the earliest submission
          const currentEarliest = earliestSubmissionsMap.get(email);
          if (data.createdAt < currentEarliest!.createdAt) {
            earliestSubmissionsMap.set(email, { id: doc.id, ...data });
          }
        }
      });

      // Convert the map values to an array
      const earliestSubmissions = Array.from(earliestSubmissionsMap.values());

      return earliestSubmissions.map(
        (submission) => submission as WaitlistSubmission,
      );
    } catch (error) {
      console.error('Error fetching waitlist submissions:', error);
      throw error;
    }
  };

  return (
    <WaitlistSubmissionsContext.Provider
      value={{ addWaitlistSubmission, getWaitlistSubmissions }}
    >
      {children}
    </WaitlistSubmissionsContext.Provider>
  );
};
