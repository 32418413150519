import { parseISO } from 'date-fns';

import event1 from '@/assets/events/event1.png';
import event2 from '@/assets/events/event2.png';
import event3 from '@/assets/events/event3.png';

export interface Event {
  date: Date;
  dateTbd?: boolean;
  description: string;
  id: string;
  imageUrl: string;
  otherInfo: string;
  title: string;
  url: string;
}

export const allEvents: Event[] = [
  {
    date: parseISO('2025-01-29T00:00:00'),
    description: `See a sneak peek of what's to come at the first event in our space! Join us for a night of music, drinks, and fun. Meet the team and other makers, and see the new space. All are welcome!`,
    id: 'event3',
    imageUrl: event3,
    otherInfo: '- 7pm @ Brooklyn Spark (75 Stewart Ave #110)',
    title: 'Sneak Peek Party',
    url: 'https://www.instagram.com/brooklyn_spark/',
  },
  {
    date: parseISO('2024-12-10T00:00:00'),
    description: `We're back with another Meet & Greet! Come connect with fellow makers, share your ideas, and see how Brooklyn Spark is shaping up. Beginners, hobbyists, and pros alike are all welcome. We'll provide the snacks and drinks - just bring your curiosity and creativity!`,
    id: 'event2',
    imageUrl: event2,
    otherInfo: '- 7pm @ Cherry on Top',
    title: 'Another Meet & Greet Is Afoot',
    url: 'https://www.instagram.com/p/DDDF9PORuhC/',
  },
  {
    date: parseISO('2024-06-09T00:00:00'),
    description: `Join us for our first event - a Meet & Greet! Come meet other makers, creatives, and the team behind Brooklyn Spark. As always, beginners and dabblers welcome. We'll bring the drinks and snacks!`,
    id: 'event1',
    imageUrl: event1,
    otherInfo: '- 2pm @ The Ink Factory',
    title: 'Meet & Greet',
    url: 'https://www.instagram.com/stories/highlights/18028903180935974/',
  },
];
