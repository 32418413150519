import React, { useEffect, useState } from 'react';

import { Card, CardContent } from '@mui/material';

import {
  useWaitlistSubmissions,
  WaitlistSubmission,
} from '@/context/WaitlistSubmissionContext';

const AdminWaitlistSubmissions: React.FC = () => {
  const { getWaitlistSubmissions } = useWaitlistSubmissions();

  const [waitlistSubmissions, setWaitlistSubmissions] = useState<
    WaitlistSubmission[]
  >([]);

  useEffect(() => {
    const fetchWaitlistSubmissions = async () => {
      try {
        const submissions = await getWaitlistSubmissions();
        setWaitlistSubmissions(submissions);
      } catch (error) {
        console.error('Error fetching waitlist submissions:', error);
      }
    };

    fetchWaitlistSubmissions();
  }, [getWaitlistSubmissions]);

  const sortedSubmissions = waitlistSubmissions.sort(
    (a, b) => a.createdAt.toMillis() - b.createdAt.toMillis(),
  );

  return (
    <Card sx={{ marginBottom: 2 }} variant="outlined">
      <CardContent>
        <h2>Waitlist Submissions</h2>
        <p>
          <strong>Total Submissions:</strong> {waitlistSubmissions.length}
        </p>

        {/* Display a condensed list of submissions with their email and then the created at date */}
        <ul>
          {sortedSubmissions.map((submission) => (
            <li key={submission.id}>
              <strong>{submission.email}</strong> -{' '}
              {submission.createdAt.toDate().toLocaleDateString()}
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  );
};

export default AdminWaitlistSubmissions;
