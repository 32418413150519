import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  where,
} from 'firebase/firestore';

import { STRINGS } from '@/constants/strings';

import { useAuth } from './AuthContext';

import { firestore } from '../firebaseConfig';

interface UserPreferences {
  language: string;
  notificationsEnabled: boolean;
  theme: string;
}

interface AppContextType {
  updateUserPreferences: (preferences: UserPreferences) => void;
  userPreferences: UserPreferences;
}

const defaultUserPreferences = {
  language: 'en',
  notificationsEnabled: true,
  theme: 'dark',
};

const AppContext = createContext<AppContextType>({
  updateUserPreferences: async () => {},
  userPreferences: defaultUserPreferences,
});

export const useApp = () => useContext(AppContext);

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider = ({ children }: AppProviderProps) => {
  const { currentUser } = useAuth();
  const [userPreferences, setUserPreferences] = useState<UserPreferences>(
    defaultUserPreferences,
  );

  const fetchUserPreferences = useCallback(async () => {
    if (currentUser) {
      try {
        const colRef = collection(firestore, 'userPreferences');
        const whereClause = where('userId', '==', currentUser.uid);
        const q = query(colRef, whereClause);
        const querySnapshot = await getDocs(q);

        const docSnap = querySnapshot.docs[0];
        if (docSnap) {
          setUserPreferences(docSnap.data() as UserPreferences);
        }

        if (querySnapshot.docs.length > 1) {
          console.error(STRINGS.multipleUserPreferencesError);
        }
      } catch (error) {
        console.error('Error fetching user preferences:', error);
      }
    }
  }, [currentUser]);

  const updateUserPreferences = async (preferences: UserPreferences) => {
    if (currentUser) {
      setUserPreferences(preferences);
      try {
        const docRef = doc(firestore, 'userPreferences', currentUser.uid);
        await setDoc(docRef, preferences, { merge: true });
      } catch (error) {
        console.error('Error updating user preferences:', error);
      }
    }
  };

  useEffect(() => {
    fetchUserPreferences();
  }, [fetchUserPreferences]);

  const value = {
    updateUserPreferences,
    userPreferences,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
