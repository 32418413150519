import { logEvent } from 'firebase/analytics';
import { httpsCallable } from 'firebase/functions';
import { toast } from 'react-hot-toast';

import { STRINGS } from '@/constants/strings';

import { analytics } from '../firebaseConfig';

interface MailchimpResponse {
  result?: string; // or whatever shape you expect from your cloud function
}

// Custom interface for the HttpsError shape
interface FirebaseFunctionsError extends Error {
  code?: string; // e.g. "already-exists", "invalid-argument"
  details?: unknown;
}

/**
 * Attempt to sign the user up for the waitlist:
 * 1) Add email to Firestore
 * 2) Call "addEmailToWaitlist" to add them to Mailchimp
 * 3) Return `true` if successful, `false` otherwise
 */
export const handleWaitlistSignup = async (
  email: string,
  functions: import('firebase/functions').Functions,
  addWaitlistSubmission: (submission: { email: string }) => void,
): Promise<boolean> => {
  // 1) Add to Firestore first
  try {
    await addWaitlistSubmission({ email });
  } catch (err) {
    console.error('Error adding user to Firestore waitlist:', err);
  }

  // 2) Now add them to Mailchimp via Cloud Function
  try {
    const addEmailToWaitlist = httpsCallable<
      { email: string },
      MailchimpResponse
    >(functions, 'addEmailToWaitlist');
    await addEmailToWaitlist({ email });

    // Success
    toast.success(STRINGS.waitlist.success);
    logEvent(analytics, 'waitlist_signup');
    return true;
  } catch (err: unknown) {
    // Could parse the error code or message from the Firebase Function
    const errorMessage = parseMailchimpError(err);
    toast.error(errorMessage);

    return false;
  }
};

/**
 * Utility function to parse known Mailchimp errors (or fallback).
 */
export function parseMailchimpError(error: unknown): string {
  const defaultMessage = STRINGS.fallbackError;

  if (typeof error === 'object' && error !== null) {
    const errObj = error as FirebaseFunctionsError;
    // We can read `errObj.code` if we used HttpsError like 'already-exists', 'invalid-argument', 'internal'
    switch (errObj.code) {
      case 'already-exists':
        return STRINGS.mailchimpAlreadySubscribedError;
      case 'invalid-argument':
        return STRINGS.invalidEmailError;

      default:
        // If the code isn't recognized, check the message
        if (errObj.message?.includes('Member Exists')) {
          return STRINGS.mailchimpAlreadySubscribedError;
        }
        if (errObj.message?.includes('Invalid Resource')) {
          return STRINGS.invalidEmailError;
        }
        return defaultMessage;
    }
  }

  return defaultMessage;
}
