import React from 'react';

import { GlobalStyles as MuiGlobalStyles } from '@mui/material';

const GlobalStyles: React.FC = () => (
  <MuiGlobalStyles
    styles={{
      '.recharts-legend-wrapper': {
        maxHeight: '300px',
        overflowY: 'auto',
      },
      '@keyframes hammer-move': {
        '0%, 100%': {
          transform: 'rotate(0deg)',
        },
        '50%': {
          transform: 'rotate(60deg)',
        },
      },
      'html, body': {
        height: '100%',
        margin: 0,
        padding: 0,
      },
    }}
  />
);

export default GlobalStyles;
