import { useState } from 'react';

import { getFunctions } from 'firebase/functions';
import toast from 'react-hot-toast';

import { STRINGS } from '@/constants/strings';
import { useWaitlistSubmissions } from '@/context/WaitlistSubmissionContext';
import { cleanEmail, validateEmail } from '@/utils/validations';
import { handleWaitlistSignup } from '@/utils/waitlist';

export const useWaitlistForm = () => {
  const { addWaitlistSubmission } = useWaitlistSubmissions();
  const functions = getFunctions();

  const [formData, setFormData] = useState({ email: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const cleanedEmail = cleanEmail(formData.email);

    if (!validateEmail(cleanedEmail)) {
      toast.error(STRINGS.invalidEmailError);
      return;
    }

    setIsSubmitting(true);

    try {
      const success = await handleWaitlistSignup(
        formData.email,
        functions,
        addWaitlistSubmission,
      );
      if (success) {
        setFormData({ email: '' });
      }
    } catch (error) {
      // no-op
    } finally {
      setIsSubmitting(false);
    }
  };

  return { formData, handleChange, handleSubmit, isSubmitting };
};
