import React from 'react';

import { Box, Grid, Link } from '@mui/material';
import { getFunctions } from 'firebase/functions';
import { useNavigate } from 'react-router';

import InputWithButton from '@/atoms/InputWithButton';
import ROUTES from '@/constants/routes';
import { STRINGS } from '@/constants/strings';
import { useWaitlistSubmissions } from '@/context/WaitlistSubmissionContext';
import { handleWaitlistSignup } from '@/utils/waitlist';

const WaitlistForm = () => {
  const { addWaitlistSubmission } = useWaitlistSubmissions();
  const functions = getFunctions();
  const navigate = useNavigate();

  const handleWaitlistSignupWrapper = async (email: string): Promise<void> => {
    const success = await handleWaitlistSignup(
      email,
      functions,
      addWaitlistSubmission,
    );
    if (success) {
      // no-op
    }
  };

  return (
    <Box
      textAlign="center"
      pt={3}
      pb={3}
      sx={{
        bgcolor: 'secondary.main',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <Box
            sx={{ color: 'white', fontFamily: 'Bungee', fontSize: '1.5rem' }}
          >
            {STRINGS.waitlist.home.title}
          </Box>
          <Box
            sx={{
              color: 'white',
              fontSize: '0.875rem',
              fontStyle: 'italic',
              marginBottom: '10px',
            }}
          >
            <Link
              component="button"
              color="background.paper"
              onClick={() => navigate(ROUTES.WAITLIST)}
              sx={{ paddingBottom: '1px' }}
            >
              Join our waitlist
            </Link>{' '}
            to secure your spot and enjoy a 15% discount in the first 3 months
            we’re open!
          </Box>
          <InputWithButton
            focusEmailInput={false}
            handleSubmit={async (email) => handleWaitlistSignupWrapper(email)}
            placeholder={STRINGS.waitlist.home.inputPlaceholder}
            buttonText={STRINGS.waitlist.home.buttonLabel}
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default WaitlistForm;
