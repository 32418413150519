import React from 'react';

import { AutoAwesome, Hardware } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Container, TextField, Typography } from '@mui/material';

import { STRINGS } from '@/constants/strings';
import { useWaitlistForm } from '@/hooks/useWaitlistForm';

const Waitlist = () => {
  const { formData, isSubmitting, handleChange, handleSubmit } =
    useWaitlistForm();

  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Typography variant="h2" component="h1" gutterBottom align="center">
        {STRINGS.waitlist.title}
      </Typography>
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        align="center"
        sx={{ mb: 4 }}
      >
        {STRINGS.waitlist.subtitle}
      </Typography>
      <Typography
        variant="body1"
        align="center"
        color="text.secondary"
        sx={{ mb: 4 }}
      >
        Early members can enjoy a <strong>15% discount</strong> for the first
        three months we’re open—<strong>that’s just $140/month</strong> instead
        of $165—and we’re waiving initiation fees as a thank-you for supporting
        our launch. <strong>Spots are limited</strong>, so don’t miss out!
        Joining the waitlist is <strong>non-binding</strong> (no credit card
        required) - it simply ensures you’ll be the first to know when we open.
      </Typography>
      <Box sx={{ mb: 3, textAlign: 'center' }}>
        <Typography variant="h6" component="h3" gutterBottom>
          {STRINGS.waitlist.grabYourSpot} <AutoAwesome />
        </Typography>
      </Box>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
        <TextField
          fullWidth
          required
          margin="normal"
          label={STRINGS.waitlist.inputPlaceholder}
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          inputProps={{ 'data-testid': 'waitlist-email-input' }}
        />
        <LoadingButton
          type="submit"
          loading={isSubmitting}
          loadingIndicator={
            <Hardware
              sx={{
                animation: 'hammer-move 0.55s ease-in-out infinite',
                color: '#fff',
                fontSize: 24,
                transformOrigin: 'bottom center',
              }}
            />
          }
          variant="contained"
          size="large"
          fullWidth
          sx={{ mt: 2 }}
          endIcon={<AutoAwesome />}
          data-testid="waitlist-submit-button"
        >
          {STRINGS.waitlist.buttonLabel}
        </LoadingButton>
      </Box>
    </Container>
  );
};

export default Waitlist;
