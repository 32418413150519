import { HelpOption } from '@/pages/GetInvolved';

export const STRINGS = {
  callToActionTitle: 'Stay in touch!',
  classSignupLink:
    'https://docs.google.com/forms/d/1oOIN9kVM5LZYbAOFpJwrzOZVh1BrsLJ22pGgtYEM1oc',
  comingSoon: 'coming soon',
  discordLink: 'https://discord.gg/bMfBHYh5Wf',
  duplicateHelpOptionError: 'You have already added this option.',
  duplicateInterestError: 'You have already added this interest.',
  fallbackError:
    'Something went wrong. Please try again or contact support@brooklynspark.com.',
  formSubmitButtonText: 'Submit',
  goFundMeLink: 'https://gofund.me/86cc0dd2',
  googleLoginButtonText: 'Login with Google',
  homePageSubtitle:
    'Welcome to a world of your own making. A community workshop and makerspace.',
  homePageTitle: 'Brooklyn Spark',
  instagramLink: 'https://www.instagram.com/brooklyn_spark/',
  interestFormAddInterestButtonText: 'Add',
  interestFormCommentsLabel: 'Additional Comments',
  interestFormCommentsPlaceholder:
    'Comments? Cool project Ideas? Want to be more than a member and help create the space?',
  interestFormCustomInterestLabel: 'Custom interest',
  interestFormCustomInterestPlaceholder: 'e.g. "Pottery" or "Robotics"',
  interestFormDescription: `We'll keep you updated with the latest news and updates. You can unsubscribe at any time.`,
  interestFormDescription2: `We are in the early stages of planning a new maker space / community workshop somewhere in North Brooklyn, and we would love your input.`,
  interestFormErrorMessage:
    'Something went wrong. Please try again or contact us directly.',
  interestFormInterestSelectLabel: 'Select your interests',
  interestFormInterestsHelperText: 'Select one or more interests.',
  interestFormSuccessMessage: 'Thank you for sharing your interests!',
  interestFormTitle: 'Thank you for joining our mailing list!',
  invalidEmailError: 'Please enter a valid email address.',
  loginPageTitle: 'Login',
  mailchimpAlreadySubscribedError:
    'You are already subscribed to our mailing list.',
  mailchimpSubmitToastSuccess: `We'll keep you updated!`,
  multipleUserPreferencesError: 'Multiple user preferences documents found',
  navbar: {
    events: 'Events',
    home: 'Home',
    joinWaitlist: 'Join Waitlist',
    theTeam: 'The Team',
  },
  newsletterSignupButtonText: `Yes, please`,
  newsletterSignupInputPlaceholder: 'Email',
  requiredTitleError: 'Please enter a title.',
  socialButtonDiscordTitle: 'Discord',
  socialButtonInstagramTitle: 'Instagram',
  socialsTitle: 'Join the conversation!',
  supportEmail: 'support@brooklynspark.com',
  toolsSectionTitle: 'Come explore our tools',
  waitlist: {
    buttonLabel: 'Join the Waitlist',
    error:
      'An error occurred. Please try again later or contact support@brooklynspark.com.',
    grabYourSpot: 'Grab your spot on the list and be part of the magic!',
    home: {
      buttonLabel: 'Join Waitlist',
      inputPlaceholder: 'Email',
      title: 'Join the Waitlist!',
    },
    inputPlaceholder: 'Enter your email',
    subtitle:
      'Brooklyn Spark is opening soon! Join the waitlist to secure your spot and snag an exclusive 15% opening discount.',
    success: 'You have been added to the waitlist! Yay!',
    title: 'Be Part of the Spark',
  },
};

export const initialInterestOptions = [
  { title: 'Woodworking', type: 'default' },
  { title: 'Textiles', type: 'default' },
  { title: 'Metalworking', type: 'default' },
  { title: 'Ceramics', type: 'default' },
  { title: '3D Printers/lasercutters', type: 'default' },
  { title: 'Electronics', type: 'default' },
];

export const initialHelpOptions: HelpOption[] = [
  { title: 'Teach classes (likely paid)', type: 'default' },
  { title: 'Help spread the word', type: 'default' },
  { title: 'Help with the build-out of the space', type: 'default' },
  {
    title: 'Manage a section of the makerspace (e.g., what equipment to buy)',
    type: 'default',
  },
  { title: "IDK, I'm down to do whatever!", type: 'default' },
];
