import React from 'react';

import { Button, Container } from '@mui/material';
import { useNavigate } from 'react-router';

import ROUTES from '@/constants/routes';

interface NotFoundProps {}

const NotFound: React.FC<NotFoundProps> = () => {
  const navigate = useNavigate();

  return (
    <Container
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '50vh',
        justifyContent: 'center',
      }}
    >
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        Not Found
        <br />
        <Button variant="contained" onClick={() => navigate(ROUTES.HOME)}>
          Go Home
        </Button>
      </div>
    </Container>
  );
};

export default NotFound;
